import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import './style.scss';

const CategoryList = ({row, index, dropItem, cbOnSelectCategory, cbOnClickCategoryEdit}) => {

  const ref = useRef(null);
  const [{ isOver, canDrop }, drop, preview] = useDrop({
    accept: 'categoryList',
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    hover(item, monitor) {

      if (!ref.current) {
        return
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      //moveQGroup(dragIndex, hoverIndex);

    },
    drop(item, monitor) {
      const hoverIndex = index;
      if(item.isAdd) {

        //dropAddStep(item, gindex, hoverIndex);
      }
      else {
        dropItem(item, hoverIndex);
      }

    }
  });
  const isActive = isOver && canDrop;
  let backgroundColor = '#fff';
  if (isActive) {
    backgroundColor = 'darkgreen';
  } else if (canDrop) {
    backgroundColor = '#ffffff';
  }


  const [{ isDragging }, drag] = useDrag({
    item: { type: 'categoryList', row, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },

  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <li className='category-list' ref={ref} style={{ backgroundColor, opacity }} >
      <div className="dragSource"><i className="material-icons">reorder</i></div>
      <div className="item">{row.label}</div>
      <div className='edit' onClick={event => cbOnSelectCategory(row)}><i className="material-icons">arrow_forward_ios</i></div>
      <div className='edit' onClick={event => cbOnClickCategoryEdit(false, row)}><i className="material-icons">edit</i></div>
    </li>
  )
};
export default CategoryList
