import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import gql from "../utils/gql";
import _ from "underscore";

export class Law {


  @observable formTempList = [];
  @observable formTempsMeta = {};
  @observable questionGroup = [];
  @observable quesionStepList = [];


//select, date, checkbox, text, tel, address, email, percent, number, momey, currency
  @action getQuestionType = () => {
    return [
      {key: 'text', label: '텍스트'},
      {key: 'checkbox', label: '예 / 아니오'},
      {key: 'date', label: '날짜'},
      {key: 'tel', label: '전화번호'},
      {key: 'address', label: '주소'},
      {key: 'email', label: '메일'},
      {key: 'percent', label: '퍼센트'},
      {key: 'number', label: '숫자'},
      {key: 'currency', label: '금액'}
    ];
  };




  @action getQuestionGroupList = (param, cb) => {
    let questionGroup = this.questionGroup;

    if(questionGroup.length > 0) {
      return questionGroup;
    }
    else {
      let params = {
        type: 'query',
        operation: 'getQuestionGroupList',
        variables: param ? param : {limit:100},
        getData: 'docs, totalDocs'
      };

      const gpqParams = gql(params);

      return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            questionGroup = res.data.data[params.operation];


            if(typeof cb === 'function') {
              cb(questionGroup);
            }
            else {

              return questionGroup;
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    }

  };


  @action getFormTempList = (param, cb) => {
    let formTempList = this.formTempList;
    let formTempsMeta = this.formTempsMeta;

    if(formTempList.length > 0) {
      return formTempList;
    }
    else {
      let params = {
        type: 'query',
        operation: 'getFormTempList',
        variables: param ? param : {limit:100},
        getData: 'docs, totalDocs'
      };

      const gpqParams = gql(params);

      return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            formTempsMeta = res.data.data[params.operation];
            //formTempList = _.indexBy(formTempsMeta.docs, 'key' );
            formTempList = formTempsMeta.docs;

            if(typeof cb === 'function') {
              cb(formTempList);
            }
            else {
              return formTempList;
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    }

  };

  @action getQuestionList = (param, cb) => {
    let quesionStepList = this.quesionStepList;

    if(quesionStepList.length > 0) {
      return quesionStepList;
    }
    else {
      let params = {
        type: 'query',
        operation: 'getQuestionList',
        variables: param ? param : {limit:100},
        getData: 'docs, totalDocs'
      };

      const gpqParams = gql(params);

      return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            quesionStepList = res.data.data[params.operation];


            if(typeof cb === 'function') {
              cb(quesionStepList);
            }
            else {
              return quesionStepList;
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    }

  };



  @action updateFormTemp = (param, cb ) => {
    let params = {
      type: 'mutation',
      operation: 'updateFormTemp',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        console.log(res.data.errors)

        let result = res.data.data[params.operation];

        if(result) {
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }
        }
        else {
          if(res.data.errors[0]) {
            if(res.data.errors[0].message.indexOf('Existenced')) {
              alert('이미 존재하는 카테고리입니다.')
            }
            else {
              alert(res.data.errors[0].message)
            }
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action updateQuestionGroup = (param, cb ) => {

    let params = {
      type: 'mutation',
      operation: 'updateQuestionGroup',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action updateQuestion = (param, cb ) => {

    let params = {
      type: 'mutation',
      operation: 'updateQuestion',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action del = (param, cb ) => {

    let params = {
      type: 'mutation',
      operation: 'delStep',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

}
