import React, {Component, Fragment}  from 'react';
import { PropTypes } from 'prop-types';
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from "underscore";
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardHeader,
  CardBody,
  FormSelect,
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  FormRadio,
  Fade,
  FormCheckbox,
  FormInput,
  Form,
  Alert
} from "shards-react";

import QuestionDragItem from "./QuestionDragItem";

import './style.scss';

@inject(({ common, law }) => ({ delDoc:common.delDoc, getQuestionList: law.getQuestionList, getQuestionType: law.getQuestionType, updateQuestion: law.updateQuestion, }))

@observer
class QuestionList extends Component {


  @observable subCateogry = [];
  @observable seFormTemp = {};
  @observable selQGroups = [];
  @observable questionList = [];
  @observable isQModal = false;
  @observable searchKW = "";
  @observable editQuestion = {
    title: '',
    question: '',
    sample: '',
    type: '',
  };

  constructor(props) {
    super(props);
    this.questionType = this.props.getQuestionType();
    this.onClickQuestionEdit = this.onClickQuestionEdit.bind(this);

  }

  @action
  getInit = async () => {
    let getQuestionList = await this.props.getQuestionList();
    this.questionList = getQuestionList.docs ? getQuestionList.docs : [];
    this.imvbQuestionList = getQuestionList.docs ? getQuestionList.docs : [];

  };

  @action
  onChangeSearchKW = async (event) => {
    const { name, value } = event.target;
    this[name] = value;

    this.questionList = _.filter(this.imvbQuestionList, function(question){
      return question.title.indexOf(value) > -1 || question.question.indexOf(value) > -1
    });
  };

  @action
  onChangeEditQuestion = async (event) => {
    const { name, value } = event.target;
    this.editQuestion[name] = value;
  };
  onChangeEditQuestionType = async (value) => {

    this.editQuestion.type = value;
    if(this.editQuestion.type === 'checkbox') {
      this.editQuestion.option = [
        {
          question: '',
          sample: '',
          type: 'text',
        }
      ]
    }
    else {
      delete this.editQuestion.option;
    }
  };

  onChangeEditQuestionOption = async (event) => {
    const { name, value } = event.target;
    this.editQuestion.option[name] = value;
  };

  onUpdateEditQuestion = async (event) => {
    let _this = this;

    let editQuestion = {
      id:this.editQuestion._id,
      question:this.editQuestion.question,
      sample:this.editQuestion.sample,
      title:this.editQuestion.title,
      type:this.editQuestion.type
    };

    if(this.editQuestion.type === 'checkbox') {
      editQuestion.option = this.editQuestion.option;
    }
    else {
      delete editQuestion.option;
    }

    this.props.updateQuestion(editQuestion, function(res) {
      if(res.data && res.data._id) {
        _this.questionList.push(res.data);
        _this.toggle();
      }
    });
  };

  onDelEditQuestion = async (event) => {
    let _this = this;
    let delQuestion = {
      id:this.editQuestion._id
    };
    this.props.delDoc('delQuestion', delQuestion, function(res) {
      if(res.data) {
        let index = _.findIndex(_this.questionList, delQuestion);
        _this.questionList.splice(index, 1);
        _this.toggle();
      }
    });
  };

  componentWillMount(prevProps, prevState){
    this.getInit();
  }

  componentWillReceiveProps(nextProps){

  }

  onClickQuestionEdit = function(event, editItem) {
    if(editItem) {
      this.editQuestion = editItem;
    }
    else {
      this.editQuestion = {
        title: '',
        question: '',
        sample: '',
        type: '',
      };
    }
    this.isQModal = true;
  };

  toggle() {
    this.isQModal = !this.isQModal;
  }


  render() {



    return (
      <DndProvider backend={HTML5Backend}>

        <Row className="justify-content-md-center questionList">
          <Col lg="12" className="mb-0">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                질문리스트
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="9" className="mb-0">
                    <FormInput name="searchKW" value={this.searchKW} onChange={event => this.onChangeSearchKW(event)} placeholder="질문 검색하기" />
                  </Col>
                  <Col xs="3" className="pl-0 mb-0">
                    <Button className="mb-2" size="sm" block onClick={event => this.onClickQuestionEdit(event)}>질문추가</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="mb-0">
                    <div className="mb-3">
                      {this.questionList.length > 0 ?
                        this.questionList.map((row, i) => (
                          <QuestionDragItem key={i} row={row} onClickQuestionEdit={this.onClickQuestionEdit}/>
                        )) :
                        null
                      }
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal open={this.isQEditModal} toggle={this.toggle.bind(this)}>
          <ModalHeader>질문 생성</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <label htmlFor="#username">질문 수정</label>
                {/*<FormSelect value={this.seFormTemp.category.sub.key} onChange={event => this.onChangeSC(event)}>*/}
                {/*<option value=''>선택</option>*/}
                {/*{this.props.category[2].map((row, i) => (*/}
                {/*<option key={i} value={row.key}>{row.ko}</option>*/}
                {/*))}*/}
                {/*</FormSelect>*/}
              </FormGroup>
              <FormGroup>


              </FormGroup>
              <button>수정하기</button>

            </Form>


          </ModalBody>
        </Modal>


        <Modal className='questionGenModal' open={this.isQModal} toggle={this.toggle.bind(this)} centered={true}>
          <ModalHeader>질문 생성</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <h6>- 제목 입력 *</h6>
                <FormInput name="title" value={this.editQuestion.title} onChange={event => this.onChangeEditQuestion(event)} placeholder="제목을 입력해 주세요." />
              </FormGroup>
              <FormGroup>
                <h6>- 질문 입력 *</h6>
                <FormInput name="question" value={this.editQuestion.question} onChange={event => this.onChangeEditQuestion(event)} placeholder="질문을 입력해 주세요." />
              </FormGroup>
              <FormGroup>
                <h6>- 예제 입력</h6>
                <FormInput name="sample" value={this.editQuestion.sample} onChange={event => this.onChangeEditQuestion(event)} placeholder="질문을 입력해 주세요." />
              </FormGroup>
              <FormGroup >
                <h6>- 타입 선택 *</h6>
                {Object.keys(this.questionType).map((i) => (
                  <FormRadio
                    inline
                    key={i}
                    name='questionType'
                    value='value'
                    checked={this.editQuestion.type === this.questionType[i].key}
                    onChange={event => this.onChangeEditQuestionType(this.questionType[i].key)}
                  >
                    {this.questionType[i].label}
                  </FormRadio>
                ))}
              </FormGroup>
              {
                this.editQuestion.type === 'checkbox' ?
                  <FormGroup>
                    <h6>- 옵션 설정 (선택)</h6>
                    <div>
                      <FormInput className='mb-1' value={this.editQuestion.option.question} name="question" onChange={event => this.onChangeEditQuestionOption(event)} placeholder="질문을 입력해 주세요." />
                      <FormInput value={this.editQuestion.option.sample} name="sample" onChange={event => this.onChangeEditQuestionOption(event)} placeholder="예제를 입력해 주세요." />
                    </div>
                  </FormGroup> : null
              }

              <div className='btn-box'>
                <Button onClick={event => this.onUpdateEditQuestion()}>{!this.editQuestion._id ? '생성하기' : '수정하기'}</Button>
                { this.editQuestion._id ? <Button theme="danger" onClick={event => this.onDelEditQuestion()}>삭제하기</Button> : null }
              </div>

            </Form>


          </ModalBody>
        </Modal>

      </DndProvider>
    )
  }
}

// QuestionList.propTypes = {
//   category: PropTypes.object.isRequired,
//   formTempList: PropTypes.object.isRequired,
//   selectedFormTepm: PropTypes.string.isRequired
// };

export default QuestionList



