import React, {Component, Fragment} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

//https://techdoma.in/how-to-sort-items-in-react-dnd-drag-and-drop/
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Fade,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  FormInput,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Form,
  Alert
} from "shards-react";

import PageTitle from "../../../components/common/PageTitle";

import FormTempSelect from "../../../components/question-form/FormTempSelect";
import EditFormTemp from "../../../components/question-form/EditFormTemp";
import QuestionList from "../../../components/question-form/QuestionList";

import './style.scss'
import _ from "underscore";

@inject(({ user }) => ({ isLogin:user.isLogin }))
@inject(({ common, law }) => ({ getCategory: common.getCategory, delDoc:common.delDoc, getFormTempList: law.getFormTempList, updateFormTemp: law.updateFormTemp}))

@observer
class QuestionForm extends Component {
  @observable allCategory = {};
  @observable selectedCategory = {};

  @observable formTempList = {};
  @observable questionGroup = false;
  @observable selectedFormTepm = '';

  @observable isQModal = false;
  @observable editFormTmpl = {
    title: '',
    questionGroups: []
  };
  @observable editFormTmplTitles = [];

  constructor(props) {
    super(props);

    this.state = {
      treeData: [{ title: 'Chicken', children: [{ title: 'Egg' }] }],
    };
  }


  componentWillMount() {
    this.getInit();
  }

  @action
  getInit = async (isForce) => {
    let allCategory = await this.props.getCategory();
    this.allCategory[1] = allCategory[1];
    this.allCategory[2] = _.groupBy(allCategory[2], function(category) { return category.cls} );
    this.allCategory[3] = _.groupBy(allCategory[3], function(category) { return category.cls} );
    this.allCategory[4] = _.groupBy(allCategory[4], function(category) { return category.cls} );
    this.selectedCategory[1] = this.allCategory[1];
    this.formTempList = await this.props.getFormTempList();
    if(isForce) {
      this.selectedFormTepm = '';
    }
  };

  onChangeFormTepm = (selectedFormTepm) => {
    this.selectedFormTepm = selectedFormTepm;
    this.editFormTmpl = this.formTempList[selectedFormTepm];
    this.questionGroup = selectedFormTepm && selectedFormTepm != '' ? this.formTempList[selectedFormTepm].questionGroups : false;
  };


  onChangeModalEditTmpe = async (event) => {
    const { name, value } = event.target;
    this.editFormTmpl[name] = value;
  };


  onChangeModalEditTmpeCategory = async (event, depth) => {

    if(event.target.value === 'no') {
      if(depth === 2) {
        this.editFormTmpl.cate_3 = 'no';
        this.editFormTmpl.cate_4 = 'no';
      }
      else if(depth === 3) {
        this.editFormTmpl.cate_4 = 'no';
      }
    }
    else {
      let value = event.target.selectedIndex-2;

      if(depth === 1) {
        value = value+1;
        let cls = this.selectedCategory[1][value].cls;
        this.selectedCategory[2] = this.allCategory[2][cls] ? this.allCategory[2][cls] : [];
        this.selectedCategory[3] = null;
        this.selectedCategory[4] = null;
        this.editFormTmpl.cate_1 = this.selectedCategory[1][value].id;
        this.editFormTmplTitles = [this.selectedCategory[1][value].label]
      }
      else if(depth === 2) {
        let cls = this.selectedCategory[2][value].cls;
        this.selectedCategory[3] = this.allCategory[3][cls] ? this.allCategory[3][cls] : [];
        this.selectedCategory[4] = null;
        this.editFormTmpl.cate_2 = this.selectedCategory[2][value].id;
        this.editFormTmplTitles.push(this.selectedCategory[2][value].label)
      }
      else if(depth === 3) {
        let cls = this.selectedCategory[3][value].cls;
        this.selectedCategory[4] = this.allCategory[4][cls] ? this.allCategory[4][cls] : [];
        this.editFormTmpl.cate_3 = this.selectedCategory[3][value].id;
        this.editFormTmplTitles.push(this.selectedCategory[3][value].label)
      }
      else if(depth === 4) {
        this.editFormTmpl.cate_4 = this.selectedCategory[4][value].id;
        this.editFormTmplTitles.push(this.selectedCategory[4][value].label)
      }
    }


    this.editFormTmpl.title = this.editFormTmplTitles.join(' - ')

  };





  onUpdateEditFormTmpl = async (event) => {
    let _this = this;
    this.editFormTmpl.order = this.formTempList.length + 1;

    this.props.updateFormTemp(this.editFormTmpl, function(res) {
      if(res) {
        _this.getInit();
        _this.toggle();
      }
    });

  };

  onDelFormTmpl = async (id) => {
    let _this = this;
    this.props.delDoc('delFormTemp', {id:id}, function(res) {
      if(res.data) {

        _this.getInit(true);
        // let index = _.findIndex(_this.questionList, delQuestion);
        // _this.questionList.splice(index, 1);
        // _this.toggle();
      }
    });
    _this.toggle();
  };
  toggle() {
    this.isQModal = !this.isQModal;
  };


  render() {


    return (
      <div>
        {/*<Container fluid className="px-0">*/}
        {/*<Alert className="mb-0">*/}
        {/*<i className="fa fa-info mx-2"></i> How you doin'? I'm just a friendly, good-looking notification message and I come in all the colors you can see below. Pretty cool, huh?*/}
        {/*</Alert>*/}
        {/*</Container>*/}
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="질문지 생성하기" subtitle="질문지" className="text-sm-left" />
          </Row>

          {/*<Colors />*/}

          <Row>
            <Col lg="8" className="mb-4">
              <Card small className="mb-4">
                <CardHeader className="border-bottom pb-0">
                  <Row className="justify-content-md-center">
                    <Col lg="8" className="mb-0">
                      <FormTempSelect formTempList={this.formTempList} cb={this.onChangeFormTepm} />
                    </Col>
                    <Col lg="3" className="mb-0">
                      <Button block onClick={event => this.toggle()}>템플릿 {this.editFormTmpl._id ? '수정하기':'새로만들기'}</Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div style={{ minHeight: 600 }}>
                    {this.questionGroup ?
                      <DndProvider backend={HTML5Backend}>
                        <EditFormTemp category={this.category} formTempList={this.formTempList} selectedFormTepm={this.selectedFormTepm} />
                      </DndProvider>
                      :
                      <Fade in={!this.questionGroup}>
                        <p className='empty'>편집할 질문지 템플릿을 선택하시거나 '새로만들기' 버튼을 클릭하세요.</p>
                      </Fade>
                    }
                  </div>
                </CardBody>

              </Card>
            </Col>
            <Col lg="4" className="mb-4">
              <QuestionList />
            </Col>

          </Row>
        </Container>

        <Modal className='formTmplEditModal' open={this.isQModal} toggle={this.toggle.bind(this)} centered={true}>
          <ModalHeader>템플릿 생성/수정</ModalHeader>
          <ModalBody>
            <Form>

              <FormGroup>
                <h6>- 카테고리명 선택 *</h6>
                <FormSelect onChange={event => this.onChangeModalEditTmpeCategory(event, 1)} value={this.editFormTmpl.cate_1}>
                  <option value=''>{this.selectedCategory[1] ? '- 1차 카테고리 선택 -' : '로딩중...'}</option>
                  {
                    this.selectedCategory[1] ?
                    Object.keys(this.selectedCategory[1]).map((key, i) => (
                    <option value={this.selectedCategory[1][key].id} key={i}>{this.selectedCategory[1][key].label}</option>
                    )) : null
                  }
                </FormSelect>
                <FormSelect onChange={event => this.onChangeModalEditTmpeCategory(event, 2)} value={this.editFormTmpl.cate_2}>
                  <option value=''>- 2차 카테고리 선택 -</option>
                  <option value={'no'}>해당없음</option>
                  {
                    this.selectedCategory[2] ?
                      Object.keys(this.selectedCategory[2]).map((key, i) => (
                        <option value={this.selectedCategory[2][key].id} key={i}>{this.selectedCategory[2][key].label}</option>
                      )) : null
                  }
                </FormSelect>
                <FormSelect onChange={event => this.onChangeModalEditTmpeCategory(event, 3)} value={this.editFormTmpl.cate_3}>
                  <option value=''>- 3차 카테고리 선택 -</option>
                  <option value={'no'}>해당없음</option>
                  {
                    this.selectedCategory[3] ?
                      Object.keys(this.selectedCategory[3]).map((key, i) => (
                        <option value={this.selectedCategory[3][key].id} key={i}>{this.selectedCategory[3][key].label}</option>
                      )) : null
                  }
                </FormSelect>
                <FormSelect onChange={event => this.onChangeModalEditTmpeCategory(event, 4)} value={this.editFormTmpl.cate_4}>
                  <option value=''>- 4차 카테고리 선택</option>
                  <option value={'no'}>해당없음</option>
                  {
                    this.selectedCategory[4] ?
                      Object.keys(this.selectedCategory[4]).map((key, i) => (
                        <option value={this.selectedCategory[4][key].id} key={i}>{this.selectedCategory[4][key].label}</option>
                      )) : null
                  }
                </FormSelect>
                <FormGroup>
                  <h6>- 제목</h6>
                  <FormInput name="title" value={this.editFormTmpl.title} onChange={event => this.onChangeModalEditTmpe(event)} placeholder="카테고리명을 입력해 주세요." />
                </FormGroup>

              </FormGroup>
              <div className='btn-box'>
                { this.editFormTmpl._id ? <Button theme="danger" onClick={event => this.onDelFormTmpl(this.editFormTmpl._id)}>삭제하기</Button> : null }
                <Button onClick={event => this.onUpdateEditFormTmpl()}>{!this.editFormTmpl._id ? '생성하기' : '수정하기'}</Button>
              </div>

            </Form>


          </ModalBody>
        </Modal>

      </div>
    )
  }
}

export default QuestionForm
