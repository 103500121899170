exports.Common = {
  realEscapeString: function(str) {
    return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
      switch (char) {
        case "\0":
          return "\\0";
        case "\x08":
          return "\\b";
        case "\x09":
          return "\\t";
        case "\x1a":
          return "\\z";
        case "\n":
          return "\\n";
        case "\r":
          return "\\r";
        case "\"":
        case "'":
        case "\\":
        case "%":
          return "\\"+char; // prepends a backslash to backslash, percent,
                            // and double/single quotes
      }
    });
  },
  addslashes: function(str) {
    return str.replace(/\\/g, '\\\\').
    replace(/\u0008/g, '\\b').
    replace(/\t/g, '\\t').
    replace(/\n/g, '\\n').
    replace(/\f/g, '\\f').
    replace(/\r/g, '\\r').
    replace(/'/g, '\\\'').
    replace(/"/g, '\\"');
  },
  escapeHtml: function(str) {
    return String(str).replace(/[&"`\/]/g, function (s) {
      let entityMap = { '&': '&amp;', '"': '&quot;', '/': '/', '`': '&#x60;' };
      return entityMap[s];
    });
  }
};
