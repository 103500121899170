import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import gql from "../utils/gql";
import {Common as Util} from "../utils/common";


export class Board {


  @action getBoardList = (param, cb ) => {
    let params = {
      type: 'query',
      operation: 'getBoardList',
      variables: param,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action getBoard = (param, cb ) => {
    let params = {
      type: 'query',
      operation: 'getBoard',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action updateBoard = (param, cb ) => {
    if(param._id) {
      param.id = param._id;
      delete param._id;
    }
    if(typeof param.author === 'object') {
      param.author = param.author._id;
    }
    delete param.view;
    delete param.reply;
    delete param.updated_at;
    delete param.created_at;
    delete param.__v;
    param.content = param.content.replace(/\n/g, '<br/>');
    param.content = Util.escapeHtml(param.content);

    let params = {
      type: 'mutation',
      operation: 'updateBoard',
      variables: param,
      getData: 'title'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        console.log(res);
        cb();
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action getBoardCommentList = (param, cb ) => {
    let params = {
      type: 'query',
      operation: 'getBoardCommentList',
      variables: param,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };




  @action updateBoardComment = (param, cb ) => {
    let params = {
      type: 'mutation',
      operation: 'updateBoardComment',
      variables: param,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        cb(res);
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };




}
