import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import axios from "axios";
import { decorate, observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import {reactLocalStorage as Storage} from "reactjs-localstorage";

import 'moment';
import 'moment/locale/ko';
import 'moment-timezone';

import routes from "./routes";
import withTracker from "./withTracker";
import history from "./utils/history";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/shards-dashboards.1.1.0.css";
import "./assets/custom.css";




@inject(({ common }) => ({ getSetting: common.getSetting }))
@inject(({ user }) => ({ authCheck: user.authCheck, getRfAuth: user.getRfAuth, isLogin:user.isLogin, permissions:user.permissions, me:user.me, doLogOut:user.doLogOut }))
@inject(({ user }) => ({ authCheck: user.authCheck, getRfAuth: user.getRfAuth }))
@observer
class App extends Component {

  constructor(props) {
    super(props);
    axios.defaults.baseURL = 'https://onnomads.com/onnomad';
    //axios.defaults.baseURL = '/onnomad';
    axios.defaults.timeout = 2500;
    const { getRfAuth } = this.props;


    let noneAuthRoute = ['/signin', '/signup'];
    axios.interceptors.response.use(function (res) {
      //console.log(res)
      //todo auth 체크하여 유효하지 않으면 로그아웃
      return res;
    }, function (error) {
      // Do something with response error
      if(error.response && error.response.data && error.response.data.errors) {

        let errMes = error.response.data.errors[0].message;
        if(errMes === 'Context creation failed: jwt malformed') {
          //todo 잘못된 토큰 또는 토큰값이 없는경우
        }
        else if(errMes === 'Context creation failed: jwt expired') {
          getRfAuth(function(data) {
            console.log(data);
            //todo logout => 로그인 팝업 또는 로그인 페이지로 이동...
          });
        }
      }
      else {
        console.log(error);
      }

    });
    if(Storage.get('token')) {
      this.props.authCheck();
    }

    this.props.getSetting();


  }

  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <div>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                history={history}
                component={withTracker(props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
            );
          })}
        </div>
      </Router>
    );
  }
}

export default App;
