import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import gql from "../utils/gql";
import {Common as Util} from "../utils/common";


export class WebToApp {


  @action getWTAList = (variables, cb) => {
    let params = {
      type: 'query',
      operation: 'getWTAList',
      variables: variables ? variables : {limit:100},
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action getWTA = (param, cb ) => {
    let params = {
      type: 'query',
      operation: 'getWTA',
      variables: param,
      getData: 'data'
    };


    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action genAppId = (variables, cb) => {
    let params = {
      type: 'mutation',
      operation: 'genAppId',
      variables: {url: variables.url},
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action updateWTA = (variables, cb) => {
    if(variables._id) {
      variables.id = variables._id;
      delete variables._id;
    }

    delete variables.updated_at;
    delete variables.created_at;
    delete variables.__v;

    let params = {
      type: 'mutation',
      operation: 'updateWTA',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

}
