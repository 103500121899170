import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import gql from "../utils/gql";




// const SINGIN = gql`
// query signIn ($userId: String!, $password: String!) {
//         signIn(userId: $userId, password: $password) {
//         name email token rfToken
//     }
// }`;

export class User {

    @observable isLogin = false;

    @observable me = {
        email : 'ee',
        name : 'ee'
    };

    @action doSignup = (data, cb) => {

        let params = {
            type: 'mutation',
            operation: 'signUp',
            variables: data,
            getData: 'userId'
        };
        const gpqParams = gql(params);

        axios.post('/', gpqParams)
            .then(function (res) {
                if(typeof cb === 'function') {
                    cb(res.data.data[params.operation]);
                }
                else {
                    return res.data.data[params.operation];
                }

            })
            .catch(function (error) {
                if(typeof cb === 'function') {
                    cb(error)
                }
                return error;
            });
    };

    @action setMe = (info) => {
        if(info) {
            this.isLogin = info.userId ? true: false;
            this.me = info;
        }
        else {
            this.isLogin = false;
            this.me = {};
        }
    };


    @action doLogin = (data, cb ) => {
        let authCheck = this.authCheck;
        let params = {
            type: 'query',
            operation: 'signIn',
            variables: data,
            getData: 'userId name email token rfToken'
        };
        const gpqParams = gql(params);

        axios.post('/', gpqParams)
            .then(function (res) {
                let resData = res.data.data[params.operation];
                Storage.set('userId', resData.userId);
                Storage.set('token', resData.token);
                Storage.set('rfToken', resData.rfToken);
                authCheck();
                if(typeof cb === 'function') {
                    cb(res.data.data[params.operation]);
                }
                else {
                    return res.data.data[params.operation];
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    @action doLogOut = (data) => {
        Storage.clear();
        this.setMe(false);
    };

    @action authCheck = (data) => {

        const {setMe} = this;

        let params = {
            type: 'query',
            operation: 'getMe',
            variables: {},
            getData: 'userId name email'
        };

        const token = Storage.get('token');
        const rfToken = Storage.get('rfToken');

        axios.defaults.headers.common['Authorization'] = token;
        axios.defaults.headers.common['rfAuthorization'] = rfToken;


        const gpqParams = gql(params);

        axios.post('/', gpqParams)
            .then(function (res) {
                if(res && res.data) {
                    setMe(res.data.data.getMe)
                }
            });
    };

    @action getRfAuth = (cb) => {
        let authCheck = this.authCheck;
        const userId = Storage.get('userId');
        let params = {
            type: 'mutation',
            operation: 'getRfAuth',
            variables: {userId: userId},
            getData: 'token rfToken'
        };
        const gpqParams = gql(params);


        axios.post('/', gpqParams)
            .then(function (res) {
                if(!res || res.data.errors) {
                    if(!res) {
                        cb(false);
                    }
                    else if(res.data.errors[0].message === 'Invalid rfAuth.') {
                        cb(false);
                    }
                }
                else {
                    let resData = res.data.data[params.operation];
                    Storage.set('token', resData.token);
                    Storage.set('rfToken', resData.rfToken);
                    Storage.set('userId', resData.userId);
                    authCheck();
                    cb(true);
                }
            });
    };



}
