import React, {Component, Fragment} from 'react';
import { PropTypes } from 'prop-types';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from "underscore";

import { Container, Row, Col } from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import Editor from "../../../components/board/Editor";
import SidebarActions from "../../../components/add-new-post/SidebarActions";
import SidebarCategories from "../../../components/add-new-post/SidebarCategories";


@inject(({ common }) => ({ getConBoard:common.getConBoard, delDoc:common.delDoc }))
@inject(({ board }) => ({ getBoard:board.getBoard, getBoardList:board.getBoardList, updateBoard:board.updateBoard }))

@observer
class BoardEdit extends Component {

  @observable boardEdit = {};

  constructor(props) {
    super(props);
    this.boardEdit = _.defaults(this.props.location.state, this.boardEdit);
    this.boardkey = this.props.match.params.key;
    this.targetBoard = this.props.getConBoard(this.boardkey);

    if(this.props.match.params.id) {
      this.getBoard(this.props.match.params.id);
    }
    else {
      this.boardEdit = {
        cbdId: this.targetBoard._id,
        author: 'aaddmmiinnn',
        title: '',
        images: '',
        content: '',
        isSecret: false,
        isPublic: true,
      };
    }
    this.updateBoard = this.updateBoard.bind(this);
  }



  @action
  getBoard = async (id) => {
    let board = await this.props.getBoard({ id:id });
    if(board && board.data) {
      this.boardEdit = board.data;
    }
  };


  @action
  updateBoard = async (boardEdit) => {
    let _this = this;
    this.props.updateBoard(boardEdit, function(res) {
      _this.props.history.push('/board/' + _this.boardkey);
    })

  };

  render() {

    return (

      <Container fluid className="main-content-container px-4 pb-4">


        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title={this.targetBoard.name + ' 게시글 작성'} subtitle={this.targetBoard.key + ' Edit'} className="text-sm-left" />
        </Row>

        <Row>
          <Col lg="9" md="12">
            {this.boardEdit.cbdId && this.boardEdit.author ?
              <Editor boardEdit={this.boardEdit} cbUpdateBoard={this.updateBoard} /> : null
            }
          </Col>

          <Col lg="3" md="12">
            {/*<SidebarActions />*/}
            {/*<SidebarCategories />*/}
          </Col>
        </Row>
      </Container>
    )
  }
}
BoardEdit.propTypes = {
  history: PropTypes.object.isRequired
};
export default BoardEdit;
