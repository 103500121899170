import React, {useState} from "react";
import { InputGroup, InputGroupAddon, InputGroupText, FormTextarea, Card, CardBody, Form, FormInput, Button } from "shards-react";

import './style.scss';


const CommentForm = ({parentId, cbUpdateComment}) => {
  console.log(parentId)

  const [comment, setComment] = useState({content: '', parentId: parentId});

  const handleChange = event => {
    if(event.target.name) {
      setComment({...comment, [event.target.name]: event.target.value })
    }
  };

  const updateComment = event => {
    cbUpdateComment(comment, function(res) {
      console.log(res);
    })
  };

  return (
    <form className="ui reply">
      <div className="field">
        <InputGroup>
          <FormTextarea name="content" onChange={event => handleChange(event)} />
          <InputGroupAddon type="append">
            <Button theme="secondary" onClick={event => updateComment(event)}>등록</Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    </form>
  )
};


const Comment = ({ commentList , cbUpdateComment }) => {

  const [isUpdate, setIsUpdate] = useState(false);
  const [comment, setComment] = useState({content: ''});
  const [commentListDocs, setCommentList] = useState(commentList.docs);


  const toggleShowForm = (event, i) => {
    commentListDocs[i].showCommentForm = !commentListDocs[i].showCommentForm;
    setCommentList(commentListDocs);
    setIsUpdate(!isUpdate)
  };

  const handleChange = event => {
    if(event.target.name) {
      setComment({...comment, [event.target.name]: event.target.value })
    }
  };


  const updateComment = event => {
    cbUpdateComment(comment, function(res) {
      console.log(res.status);
      if(res.status === 200) {
        comment.author = {
          name: 'test'
        };
        commentListDocs.unshift(comment);
        console.log(commentListDocs)
        setCommentList(commentListDocs);
        setIsUpdate(!isUpdate)
      }
    })
  };




  return (
    <div className="ui comments">
      <h3 className="ui dividing header">Comments</h3>
      {
        commentListDocs && commentListDocs.length ?
          commentListDocs.map((item, i) => (
            <div className="comment" key={i}>
              <a className="avatar">
                <img src={item.author.avatar} />
              </a>
              <div className="content">
                <a className="author">{item.author.name}</a>
                <div className="metadata">
                  <span className="date">{item.updated_at}</span>
                </div>
                <div className="text">
                  <p>{item.content}</p>
                </div>
                <div className="actions">
                  <a className="reply" name="content" onClick={event => toggleShowForm(event, i)}>답글</a>
                </div>
                {
                  item.showCommentForm ? <CommentForm parentId={item._id} cbUpdateComment={cbUpdateComment} /> : null
                }
              </div>
              {
                item.childComments && item.childComments.length > 0 ?
                  item.childComments.map((cItem, j) => (
                    <div className="comments" key={j}>
                      <div className="comment">
                        <a className="avatar">
                          <img src={cItem.author.avatar} />
                        </a>
                        <div className="content">
                          <a className="author">{cItem.author.name}</a>
                          <div className="metadata">
                            <span className="date">{cItem.updated_at}</span>
                          </div>
                          <div className="text">
                            {cItem.content}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                  :
                  null
              }
            </div>

          ))
          :
          null

      }
      <hr />
      <form className="ui reply">
        <div className="field">
          <InputGroup>
            <FormTextarea name="content" onChange={event => handleChange(event)} />
            <InputGroupAddon type="append">
              <Button theme="secondary" onClick={event => updateComment(event)}>등록</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </form>
    </div>
  )
};

export default Comment;
