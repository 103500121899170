import React, {Component, Fragment} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from "underscore";

import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import Comment from "../../../components/board/Comment";

import './style.scss';



@inject(({ common }) => ({ getConBoard:common.getConBoard, delDoc:common.delDoc }))
@inject(({ board }) => ({ getBoard:board.getBoard, getBoardCommentList:board.getBoardCommentList, updateBoardComment:board.updateBoardComment }))

@observer
class BoardDetail extends Component {

  @observable board = {};

  constructor(props) {
    super(props);
    this.init(this.props.match.params.id);

  }

  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (id) => {
    let board = await this.props.getBoard({ id:id });
    this.commentList = await this.props.getBoardCommentList({ boardId:id, limit:20 });

    this.board = board.data;
  };


  updateBoardComment = async (newComment, cb) => {

    newComment.boardId = this.board._id;
    newComment.author = 'aaddmmiinnn';
    let result = await this.props.updateBoardComment(newComment, function(res) {
      if(typeof cb === 'function') {
        cb(res);
      }
      else {
        return result;
      }
    });


  };

  render() {

    return (

      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title={'제목 : ' + this.board.title} subtitle={''} className="text-sm-left" />
        </Row>


        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{this.board.title}</h6>
              </CardHeader>
              <CardBody className="p-3">
                <Row>
                  <Col>
                    <p dangerouslySetInnerHTML={ {__html: this.board.content} }></p>
                  </Col>
                </Row>
                <Row>

                  <Col>
                    {
                      this.commentList && this.commentList.docs ?
                        <Comment commentList={this.commentList} cbUpdateComment={this.updateBoardComment}/> : null
                    }
                  </Col>

                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>


      </Container>
    )
  }
}

export default BoardDetail;
