import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import './style.scss';

const QuestionSortInGroup = ({gindex, index, question, onClickCB, moveQuestion, dropQuestion, dropAddQuestion, cbRemoveQuestionInGroup}) => {

  const ref = useRef(null);
  const [{ isOver, canDrop }, drop, preview] = useDrop({
    //accept: 'StepSortInGroup StepSortInGroup-' + groupIndex,
    accept: 'QuestionSortInGroup',
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    hover(item, monitor) {

      if(!question._id) {
        return
      }

      if (!ref.current) {
        return
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveQuestion(item.gindex, dragIndex, gindex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      //item.index = hoverIndex
    },
    drop(item, monitor) {
      const hoverIndex = index;
      if(item.isAdd) {
        dropAddQuestion(item, gindex, hoverIndex);
      }
      else {
        if(!question._id) {
          return
        }
        dropQuestion(item, gindex, hoverIndex);
      }

    }
  });
  const isActive = isOver && canDrop;
  let backgroundColor = '#fff';
  if (isActive) {
    backgroundColor = 'darkgreen';
  } else if (canDrop) {
    backgroundColor = '#ffffff';
  }


  const [{ isDragging }, drag] = useDrag({
    item: { type: 'QuestionSortInGroup', question, gindex, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },

  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
  <div className="QuestionSortInGroup" ref={ref} index={index} gindex={gindex} style={{ backgroundColor, opacity }}>

    <div className="dragSource">
      {
        question._id ?
          <i className="material-icons">
            reorder
          </i>
          :
          <i className="material-icons">
            add_circle_outline
          </i>
      }

    </div>
    <div className="item">
      {
        question._id ?
          <p className="mb-0"> 질문 : {question.question} <span className='btn-del'><i className="material-icons" onClick={event => cbRemoveQuestionInGroup(gindex, index)}>delete</i></span></p>
          :
          <p className="mb-0"> {question.question}</p>
      }
      {
        question.type == 'checkbox' && question.option ?
          <p className="mb-0">옵션 : {question.option[0].question}</p>
          : null
      }
    </div>
  </div>
  )
}
export default QuestionSortInGroup
