import React from 'react'

import { useDrag } from 'react-dnd'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert
} from "shards-react";

import './style.scss';

const QuestionDragItem = ({row, onClickQuestionEdit}) => {

  let question = row;
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'QuestionSortInGroup', question, isAdd: true  },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        // alert(`You dropped ${item.name} into ${dropResult.name}!`)
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <div className="questionDragItem" ref={drag} style={{ opacity }}>
      <div className="dragSource">
        <i className="material-icons">
          toll
        </i>
      </div>
      <div className="item">
        {row.question}
      </div>
      <div className='edit' onClick={event => onClickQuestionEdit(event, question)}>
        <i className="material-icons">
          edit
        </i>
      </div>
    </div>
  )
}
export default QuestionDragItem
