import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route containers
import QuestionForm from "./containers/pages/Question-form";
import WebToApp from "./containers/pages/Web-to-app";
import GodoSkin from "./containers/pages/Godo-skin";
import CafeSkin from "./containers/pages/Cafe-skin";
import Counsel from "./containers/pages/Counsel";
import CategoryMng from "./containers/pages/Category-mng";
import User from "./containers/pages/User";
import Board from "./containers/pages/Board";
import BoardDetail from "./containers/pages/BoardDetail";
import BoardEdit from "./containers/pages/BoardEdit";


// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/blog-overview" />
  },
  {
    path: "/web2app",
    layout: DefaultLayout,
    component: WebToApp
  },
  {
    path: "/godoskin",
    layout: DefaultLayout,
    component: GodoSkin
  },
  {
    path: "/CafeSkin",
    layout: DefaultLayout,
    component: CafeSkin
  },
  {
    path: "/question-form",
    layout: DefaultLayout,
    component: QuestionForm
  },
  {
    path: "/cousel",
    layout: DefaultLayout,
    component: Counsel
  },
  {
    path: "/user",
    layout: DefaultLayout,
    component: User
  },
  {
    path: "/board/:key",
    layout: DefaultLayout,
    component: Board
  },
  {
    path: "/board-detail/:id",
    layout: DefaultLayout,
    component: BoardDetail
  },
  {
    path: "/board-edit/:key",
    layout: DefaultLayout,
    component: BoardEdit
  },
  {
    path: "/board-edit/:key/:id",
    layout: DefaultLayout,
    component: BoardEdit
  },
  {
    path: "/categoryMng",
    layout: DefaultLayout,
    component: CategoryMng
  },




  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  }
];
