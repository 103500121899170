import React, {Component} from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup, InputGroup, InputGroupText, FormInput, InputGroupAddon, Button
} from "shards-react";

import PageTitle from "../../../components/common/PageTitle";
import HTML5Backend from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import Moment from 'react-moment';
import moment from 'moment'
import {Link} from "react-router-dom";
import QuestionDragItem from "../../../components/question-form/QuestionDragItem";


@inject(({ webToApp }) => ({ getWTAList:webToApp.getWTAList, updateWTA: webToApp.updateWTA, genAppId: webToApp.genAppId }))

@observer
class WebToApp extends Component {

  @observable today = moment().format("YYYY-MM-DD");
  @observable isQModal = false;
  @observable ableChangeExpiry = false;
  @observable getWTAList = [];
  @observable WTA = {
    appName: "",
    url: "",
    appId: "",
    key: "",
    keyExpiry: "",
    version: "",
    worker: ""
  };

  constructor(props) {
    super(props);

    this.onChangeInputs = this.onChangeInputs.bind(this);
  }

  componentWillMount(prevProps, prevState){
    this.getInit();
  }

  componentWillReceiveProps(nextProps){

  }

  @action
  getInit = async () => {
    var getWTAListObj = await this.props.getWTAList();
    this.getWTAList = getWTAListObj.docs
    console.log(this.getWTAList)
  };

  onClickWebToWTAEditModal = function(row) {
    if(row != "new") {
      this.WTA = row;
    }
    this.isQModal = true;
  };

  onChangeInputs = async (event) => {
    const { name, value } = event.target;
    this.WTA[name] = value;
  };

  onClickGenAppId = function() {
    let _this = this;
    if(this.WTA.url) {
      if(!this.WTA.appId && !this.WTA.key) {
        this.props.genAppId(this.WTA, function(res) {
          _this.WTA.appId = res.data.appId;
          _this.WTA.key = res.data.key;
          _this.ableChangeExpiry = true;
        });
      }
      else {
        alert("생성불가.");
      }
    }
    else {
      _this.ableChangeExpiry = false;
      alert("url을 입력해 주세요.");
    }
  };

  onClickChangeExpiry = function() {
    let _this = this;
    _this.ableChangeExpiry = true;
  };

  onSubmitWTAEdit = function() {

    this.props.updateWTA(this.WTA, function(res) {
      console.log(res);
    })
  };

  onClickDelWTA = function() {

  };


  modalToggle() {
    this.isQModal = !this.isQModal;
  }

  render() {

    return (
      <DndProvider backend={HTML5Backend}>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="웹투앱" subtitle="모바일 웹페이지를 앱으로 제작 및 서비스" className="text-sm-left" />
          </Row>
          <Row noGutters className="page-header py-0">
            <Col className="text-right">
              <Button onClick={event => this.onClickWebToWTAEditModal('new')} theme="primary" className="mb-2 mr-1">
                생성하기
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Active App</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #No
                        </th>
                        <th scope="col" className="border-0">
                          쇼핑몰 URL
                        </th>
                        <th scope="col" className="border-0">
                          앱 이름
                        </th>
                        <th scope="col" className="border-0">
                          앱 ID
                        </th>
                        <th scope="col" className="border-0">
                          앱 버전
                        </th>
                        <th scope="col" className="border-0">
                          라이센스 기간(남은일)
                        </th>
                        <th scope="col" className="border-0">
                          담당자
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.getWTAList.length > 0 ?
                      this.getWTAList.map((row, i) => (
                        <tr key={i} >
                          <td>{i+1}</td>
                          <td><a target="_blank" href={row.url}>{row.url}</a></td>
                          <td><span className={'cursor-pointer'} onClick={event => this.onClickWebToWTAEditModal(row)}>{row.appName}</span></td>
                          <td><span className={'cursor-pointer'} onClick={event => this.onClickWebToWTAEditModal(row)}>{row.appId}</span></td>
                          <td>{row.version}</td>
                          <td>
                            <Moment format="YYYY년 MM월 DD일">
                              {row.keyExpiry}
                            </Moment>
                            &nbsp;(<Moment diff={this.today} unit="days">
                              {row.keyExpiry}
                            </Moment>일)
                          </td>
                          <td>{row.worker}</td>
                        </tr>
                      )) :
                      null
                    }
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal className='questionGenModal' open={this.isQModal} toggle={this.modalToggle.bind(this)} centered={true}>
          <ModalHeader>웹투앱 {this.WTA._id ? "수정" : "생성"}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <h6>- 쇼핑몰 URL(모바일) *</h6>
                <FormInput value={this.WTA.url} name="url" onChange={event => this.onChangeInputs(event)} placeholder="쇼핑몰 URL을 입력해 주세요." />
              </FormGroup>
              <FormGroup>
                <h6>- 앱이름 *</h6>
                <FormInput value={this.WTA.appName} name="appName" onChange={event => this.onChangeInputs(event)} placeholder="앱이름을 입력해 주세요." />
              </FormGroup>
              <FormGroup>
                <h6>- 앱 ID</h6>
                <InputGroup>
                  <FormInput value={this.WTA.appId} name="appId" disabled placeholder="앱 ID" />
                  <InputGroupAddon type="append">
                    <Button onClick={event => this.onClickGenAppId()} theme="secondary">생성하기</Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <h6>- KEY</h6>
                <InputGroup>
                  <FormInput value={this.WTA.key} name="key" disabled placeholder=" API KEY." />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <h6>- 라이센스 기간</h6>
                <InputGroup>
                  <FormInput value={this.WTA.keyExpiry} name="keyExpiry" type="date" disabled={!this.ableChangeExpiry} onChange={event => this.onChangeInputs(event)} placeholder="라이센스 기간을 입력해 주세요." />
                  <InputGroupAddon type="append">
                    <Button theme="secondary" onClick={event => this.onClickChangeExpiry()}>갱신하기</Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <h6>- 앱버전 *</h6>
                <FormInput value={this.WTA.version}  name="version" onChange={event => this.onChangeInputs(event)} placeholder="버전을 입력해 주세요." />
              </FormGroup>
              <FormGroup>
                <h6>- 담당자 *</h6>
                <FormInput value={this.WTA.worker}  name="worker" onChange={event => this.onChangeInputs(event)} placeholder="담당자 이름을 입력해 주세요." />
              </FormGroup>
              <div className='btn-box'>
                <Button onClick={event => this.onSubmitWTAEdit()}>{!this.WTA._id ? '생성하기' : '수정하기'}</Button>
                { this.WTA._id ? <Button theme="danger" onClick={event => this.onClickDelWTA()}>삭제하기</Button> : null }
              </div>
            </Form>


          </ModalBody>
        </Modal>
      </DndProvider>
    )
  }
}


export default WebToApp;
