import React from "react";
import { observer } from 'mobx-react'

import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect
} from "shards-react";

const FormTempSelect = observer(({ formTempList, cb }) => {

  const onChange = function(event) {
    cb(event.target.value)
  };

  return (
    <div>
      <InputGroup className="mb-3">
        <InputGroupAddon type="prepend">
          <InputGroupText>템플릿 선택</InputGroupText>
        </InputGroupAddon>
        <FormSelect onChange={onChange.bind(this)}>
          <option value=''>선택</option>
          {Object.keys(formTempList).map((key, i) => (
            <option value={key} key={i}>{formTempList[key].title}</option>
          ))}
        </FormSelect>
      </InputGroup>
    </div>
    )
});

export default FormTempSelect;
