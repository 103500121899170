import React, {Component} from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup, InputGroup, InputGroupText, FormInput, InputGroupAddon, Button, FormCheckbox
} from "shards-react";

import PageTitle from "../../../components/common/PageTitle";
import HTML5Backend from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import Moment from 'react-moment';
import moment from 'moment'
import {Link} from "react-router-dom";
import QuestionDragItem from "../../../components/question-form/QuestionDragItem";



@inject(({ skin }) => ({ getSkinList: skin.getSkinList, updateSkin:skin.updateSkin }))

@observer
class CafeSkin extends Component {

  @observable today = moment().format("YYYY-MM-DD");
  @observable isQModal = false;
  @observable ableChangeExpiry = false;
  @observable getSkinList = [];
  @observable WTA = {
    appName: "",
    url: "",
    appId: "",
    key: "",
    keyExpiry: "",
    version: "",
    worker: ""
  };

  constructor(props) {
    super(props);
  }

  componentWillMount(prevProps, prevState){
    this.getInit();
  }

  componentWillReceiveProps(nextProps){

  }

  @action
  getInit = async () => {
    var getSkinListObj = await this.props.getSkinList({platform:'cafe24', limit:100});
    this.getSkinList = getSkinListObj.docs
    console.log(this.getSkinList)
  };

  onChangeInputs = async (event, row) => {
    const { name, value } = event.target;
    row[name] = value;
  };

  onChangeChecked = async (row) => {
    row.isChecked = !row.isChecked;
    let variables = {id:row._id, isChecked: row.isChecked, platform:row.platform};
    this.props.updateSkin(variables, function(res) {
      console.log(res);
    })
  };

  onBlurInputs = async (event, row) => {
    const { name, value } = event.target;
    let variables = {id:row._id, platform:row.platform};
    variables[name] = value;
    this.props.updateSkin(variables, function(res) {
      console.log(res);
    });
  };




  render() {

    return (
      <DndProvider backend={HTML5Backend}>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="카페24 스킨 리스트" subtitle="불법 복제 확인" className="text-sm-left" />
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">

                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #No
                      </th>
                      <th scope="col" className="border-0">
                        쇼핑몰 URL
                      </th>
                      <th scope="col" className="border-0">
                        등록일
                      </th>
                      <th scope="col" className="border-0">
                        확인여부
                      </th>
                      <th scope="col" className="border-0">
                        메모
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getSkinList.length > 0 ?
                      this.getSkinList.map((row, i) => (
                        <tr key={i} >
                          <td>{i+1}</td>
                          <td><a target="_blank" rel="noopener noreferrer" href={'http://' + row.domainUrl}>{row.domainUrl}</a></td>
                          <td>
                            <Moment format="YYYY년 MM월 DD일 HH시 mm분">
                              {row.created_at}
                            </Moment>
                          </td>
                          <td>
                            <fieldset>
                              <FormCheckbox toggle small checked={row.isChecked} onChange={event=> this.onChangeChecked(row)} ></FormCheckbox>
                            </fieldset>
                          </td>
                          <td>
                            <FormInput name="memo" value={row.memo} onChange={event => this.onChangeInputs(event, row)} onBlur={event => this.onBlurInputs(event, row)} />
                          </td>
                        </tr>
                      )) :
                      null
                    }
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </DndProvider>
    )
  }
}


export default CafeSkin;
