import React, {Component, Fragment} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import _ from "underscore";

//https://techdoma.in/how-to-sort-items-in-react-dnd-drag-and-drop/
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  FormRadio,
  Button,
  Fade,
  FormInput,
  ListGroup,
  ListGroupItem,
  Form,
  Alert
} from "shards-react";

import PageTitle from "../../../components/common/PageTitle";

import './style.scss'
import CategoryList from "../../../components/category/Category-list";


@inject(({ common }) => ({ getCategory: common.getCategory, updateCategory:common.updateCategory, updateCategoryOrder: common.updateCategoryOrder, delDoc:common.delDoc }))

@observer
class CategoryMng extends Component {
  @observable allCategory = {};
  @observable selectedCategory_2;
  @observable selectedCategory_2_temp;
  @observable selectedCategory_3;
  @observable selectedCategory_3_temp;
  @observable selectedCategory_4;
  @observable selectedCategory_4_temp;
  @observable editCategory = {};
  @observable isQModal = false;

  constructor(props) {
    super(props);

    this.onClickCategoryEdit = this.onClickCategoryEdit.bind(this);
  }

  componentWillMount() {
    this.getInit();
    this.subCategory = [];
  }

  @action
  getInit = async () => {
    let allCategory = await this.props.getCategory();
    this.allCategory[1] = allCategory[1];
    this.allCategory[2] = _.groupBy(allCategory[2], function(category) { return category.cls} );
    this.allCategory[3] = _.groupBy(allCategory[3], function(category) { return category.cls} );
    this.allCategory[4] = _.groupBy(allCategory[4], function(category) { return category.cls} );

    console.log(this.allCategory[2])
    //this.selectedCategory_2 = this.allCategory;
  };

  onSelectCategory = async (row) => {
    if(row.depth === 1) {
      this.selectedCategory_2 = this.allCategory[2][row.cls] ? this.allCategory[2][row.cls] : [];
      this.selectedCategory_2_temp = [{depth:2, cls:row.cls}]
      this.selectedCategory_3 = null;
      this.selectedCategory_4 = null;

    }
    else if(row.depth === 2) {
      this.selectedCategory_3 = this.allCategory[3][row.cls] ? this.allCategory[3][row.cls] : [];
      this.selectedCategory_4 = null;
      this.selectedCategory_3_temp = [{depth:3, cls:row.cls}]
    }
    else if(row.depth === 3) {
      this.selectedCategory_4 = this.allCategory[4][row.cls] ? this.allCategory[4][row.cls] : [];
      this.selectedCategory_4_temp = [{depth:4, cls:row.cls}]
    }

  };

  onChangeEditCategory = async (event) => {
    const { name, value } = event.target;
    this.editCategory[name] = value;
  };

  onClickCategoryEdit = function(categorys, editItem) {
    if(editItem) {
      this.editCategory = editItem;
    }
    else {
      if(categorys && categorys[0]) {
        this.editCategory = {
          depth: categorys[0].depth,
          cls: categorys[0].depth === 1 ? categorys.length + 1 : categorys[0].cls,
          label: '',
          order: categorys.length + 1,
        };
      }
      else {
        this.editCategory = {
          depth: 1,
          cls: 1,
          label: '',
          order: 1,
        };
      }

    }

    this.isQModal = true;
  };

  onUpdateEditCategory = async (event) => {
    let _this = this;
    this.props.updateCategory(this.editCategory, function(res) {
      if(res.data && res.data._id && !_this.editCategory.id) {
        res.data.id = res.data._id;
        if(_this.editCategory.depth === 1) {
          _this.allCategory[1] ? _this.allCategory[1].push(res.data) : _this.allCategory[1] = [res.data];
        }
        else if(_this.editCategory.depth === 2) {
          _this.selectedCategory_2.push(res.data);
        }
        else if(_this.editCategory.depth === 3) {
          _this.selectedCategory_3.push(res.data);
        }
        else if(_this.editCategory.depth === 4) {
          _this.selectedCategory_4.push(res.data);
        }
      }
    });
    _this.toggle();
  };

  onDelCategory = async (event) => {
    let _this = this;
    let delCategory = {
      id:_this.editCategory.id
    };

    this.props.delDoc('delCategory', delCategory, function(res) {
      if(res.data) {

        if(_this.editCategory.depth === 1) {
          let index = _.findIndex(_this.allCategory[1], _this.editCategory);
          _this.allCategory[1].splice(index, 1);
        }
        else if(_this.editCategory.depth === 2) {
          let index = _.findIndex(_this.selectedCategory_2, _this.editCategory);
          _this.selectedCategory_2.splice(index, 1);
        }
        else if(_this.editCategory.depth === 3) {
          let index = _.findIndex(_this.selectedCategory_3, _this.editCategory);
          _this.selectedCategory_3.splice(index, 1);
        }
        else if(_this.editCategory.depth === 4) {
          let index = _.findIndex(_this.selectedCategory_4, _this.editCategory);
          _this.selectedCategory_4.splice(index, 1);
        }
      }
    });
    _this.toggle();
  };

  toggle() {
    this.isQModal = !this.isQModal;
  }


  render() {


    const dropItem = (item, hoverIndex) => {

      let dragItem = item.row;
      let depth = dragItem.depth;
      if(depth === 1) {
        this.allCategory[depth].splice(item.index, 1);
        this.allCategory[depth].splice(hoverIndex, 0, dragItem);
        this.props.updateCategoryOrder({ depth: depth, categoryArr: _.pluck(this.allCategory[depth], 'id')}, function(res) {
          console.log(res);
        })
      }
      else {
        let cls = dragItem.cls;
        this.allCategory[depth][cls].splice(item.index, 1);
        this.allCategory[depth][cls].splice(hoverIndex, 0, dragItem);
        this.props.updateCategoryOrder({ depth: depth, categoryArr: _.pluck(this.allCategory[depth][cls], 'id')}, function(res) {
          console.log(res);
        })
      }


      // let dragItem = item.row;
      // let depth = dragItem.depth;
      //
      // let hoverItemOrgOrder = this.allCategory[depth][hoverIndex].order;
      // let dragItemOrgOrder = item.row.order;
      //
      //
      //
      // hoverItem.order = item.row.order;
      // //todo update pItem
      // console.log(hoverItem);
      //
      // dragItem.order = hoverItemOrgOrder;
      // console.log(dragItem)


    };




    return (
      <DndProvider backend={HTML5Backend}>
        <Container fluid className="category-mng px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="카테고리 관리" className="text-sm-left" />
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">1차 카테고리</h6>
                  <Button size="sm" onClick={event => this.onClickCategoryEdit(this.allCategory[1])}>질문추가</Button>
                </CardHeader>
                <CardBody className="p-3">
                  <ul className='category-list-wrap'>
                    {
                      this.allCategory[1] && this.allCategory[1].length > 0 ?
                        this.allCategory[1].map((row, i) => (
                          <CategoryList key={i} index={i} row={row} dropItem={dropItem} cbOnSelectCategory={this.onSelectCategory} cbOnClickCategoryEdit={this.onClickCategoryEdit}></CategoryList>
                        ))
                        : null
                    }
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">2차 카테고리</h6>
                  {
                    this.selectedCategory_2 ?
                      <Button size="sm" onClick={event => this.onClickCategoryEdit(this.selectedCategory_2_temp)}>질문추가</Button> : null
                  }
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <ul className='category-list-wrap'>
                    {
                      this.selectedCategory_2 && this.selectedCategory_2.length > 0 && this.selectedCategory_2[0].label ?
                        this.selectedCategory_2.map((row, i) => (
                          <CategoryList key={i} index={i} row={row} dropItem={dropItem} cbOnSelectCategory={this.onSelectCategory} cbOnClickCategoryEdit={this.onClickCategoryEdit}></CategoryList>
                        ))
                        : null
                    }
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">3차 카테고리</h6>
                  {
                    this.selectedCategory_3 ?
                      <Button size="sm" onClick={event => this.onClickCategoryEdit(this.selectedCategory_3_temp)}>질문추가</Button> : null
                  }
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <ul className='category-list-wrap'>
                    {
                      this.selectedCategory_3 && this.selectedCategory_3.length > 0 && this.selectedCategory_3[0].label ?
                        this.selectedCategory_3.map((row, i) => (
                          <CategoryList key={i} index={i} row={row} dropItem={dropItem} cbOnSelectCategory={this.onSelectCategory} cbOnClickCategoryEdit={this.onClickCategoryEdit}></CategoryList>
                        ))
                        : null
                    }
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">4차 카테고리</h6>
                  {
                    this.selectedCategory_4 ?
                      <Button size="sm" onClick={event => this.onClickCategoryEdit(this.selectedCategory_4_temp)}>질문추가</Button> : null
                  }
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <ul className='category-list-wrap'>
                    {
                      this.selectedCategory_4 && this.selectedCategory_4.length > 0 && this.selectedCategory_4[0].label ?
                        this.selectedCategory_4.map((row, i) => (
                          <CategoryList key={i} index={i} row={row} dropItem={dropItem} cbOnSelectCategory={this.onSelectCategory} cbOnClickCategoryEdit={this.onClickCategoryEdit}></CategoryList>
                        ))
                        : null
                    }
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal className='questionGenModal' open={this.isQModal} toggle={this.toggle.bind(this)} centered={true}>
          <ModalHeader>카테고리 생성</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <h6>- 카테고리명 입력 *</h6>
                <FormInput name="label" value={this.editCategory.label} onChange={event => this.onChangeEditCategory(event)} placeholder="카테고리명을 입력해 주세요." />
              </FormGroup>
              <div className='btn-box'>
                <Button onClick={event => this.onUpdateEditCategory()}>{!this.editCategory.id ? '생성하기' : '수정하기'}</Button>
                { this.editCategory.id ? <Button theme="danger" onClick={event => this.onDelCategory()}>삭제하기</Button> : null }
              </div>

            </Form>


          </ModalBody>
        </Modal>
      </DndProvider>
    )
  }
}



export default CategoryMng;
