import React, {useState} from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Form, FormInput, Button } from "shards-react";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'},
      {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
};
const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];





const Editor = ({ boardEdit , cbUpdateBoard}) => {

  const [board, setBoard] = useState(boardEdit);

  const handleChange = event => {
    if(event.target.name) {
      setBoard({...board, [event.target.name]: event.target.value })
    }
  };

  const handleChangeQuill = value => {
    setBoard({...board, ['content']: value })

  };


  return (
    <Card small className="mb-3">
      <CardBody>
        <Form className="add-new-post">
          <FormInput value={board.title} name="title" placeholder="제목" onChange={event => handleChange(event)} size="lg" className="mb-3" />
          <ReactQuill onChange={value => handleChangeQuill(value)} value={board.content} placeholder="내용" modules={modules} formats={formats} className="add-new-post__editor mb-1"  />
          <Button theme="primary" className="mb-2 mr-1" onClick={event => cbUpdateBoard(board)}>
            완료
          </Button>
        </Form>
      </CardBody>
    </Card>
  )
};

export default Editor;
