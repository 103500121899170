import React, {Component, Fragment} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from "underscore";

import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Button} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";


@inject(({ common }) => ({ getConBoard:common.getConBoard, delDoc:common.delDoc }))
@inject(({ board }) => ({ getBoardList:board.getBoardList, postBoard:board.postBoard }))

@observer
class Board extends Component {

  @observable boardParams = {page:1, limit:5};
  @observable boardList = [];
  @observable boardDetail = {};
  @observable isQModal = false;


  constructor(props) {
    super(props);
    this.boardkey = this.props.match.params.key;
    this.init(this.boardkey)
  }


  componentDidUpdate(prevProps, prevState){
    if(prevProps.match.params.key !== this.props.match.params.key ) {
      this.boardkey = this.props.match.params.key;
      this.init(this.boardkey);
    }
  }

  @action
  init = async (key) => {
    if(key) {
      this.targetBoard = this.props.getConBoard(key);
      this.boardParams.cbdId = this.targetBoard._id;
      if(this.boardParams.cbdId) {
        this.boardList = await this.props.getBoardList(this.boardParams);
      }
      else {
        alert('존재하지 않는 게시판 입니다.')
      }
    }
    else {
      alert('존재하지 않는 게시판 입니다.')
    }

  };

  delBoard = async (id) => {
    let _this = this;
    this.props.delDoc('delBoard', {id:id, author: 'aaddmmiinnn'}, function(res) {
      if(res.data.deletedCount > 0) {
        _this.init(_this.boardkey);
      }
    });
  };


  render() {

    return (

      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title={this.targetBoard.name} subtitle={this.targetBoard.key} className="text-sm-left" />
        </Row>

        <Row noGutters className="page-header py-4 text-right">
          <Link to={"/board-edit/" + this.boardkey}><Button theme="primary" className="mb-2 mr-1">
            글쓰기
          </Button></Link>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{this.targetBoard.name}</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      제목
                    </th>
                    <th scope="col" className="border-0">
                      작성자
                    </th>
                    <th scope="col" className="border-0">
                      작성시간
                    </th>
                    <th scope="col" className="border-0">
                      수정 / 삭제
                    </th>
                    <th scope="col" className="border-0">
                      삭제
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.boardList.docs && this.boardList.docs.length > 0 ?
                    this.boardList.docs.map((item, i) => (
                      <tr key={i}>
                        <td>{i+1}</td>
                        <td><Link to={"/board-detail/" + item._id} >{item.title}</Link></td>
                        <td>{item.author ? item.author.name : item.name}</td>
                        <td>{item.created_at}</td>
                        <td><Link to={"/board-edit/" + this.boardkey + "/" + item._id} >수정</Link></td>
                        <td><span onClick={event => this.delBoard(item._id)}>삭제</span></td>
                      </tr>
                    ))
                    :
                    null
                  }
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    )
  }
}

export default Board;
