import React, {Component, Fragment, useCallback}  from 'react';
import { PropTypes } from 'prop-types';
import { useDrop } from 'react-dnd';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from "underscore";

import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardHeader,
  CardBody,
  FormSelect,
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  FormRadio,
  Fade,
  FormCheckbox,
  FormInput,
  Form,
  Alert
} from "shards-react";


import QGroupSortInTmpl from "./QGroupSortInTmpl";
import QuestionSortInGroup from "./QuestionSortInGroup";
import NewGroupForm from "./NewGroupForm";

const style = {
  height: '12rem',
  width: '12rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  color: 'white',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
};

@inject(({ common, law }) => ({ getQuestionGroupList: law.getQuestionGroupList, getQuestionList: law.getQuestionList, updateFormTemp: law.updateFormTemp, updateQuestionGroup: law.updateQuestionGroup}))

@observer
class editFormTemp extends Component {


  @observable subCateogry = [];
  @observable seFormTemp = {};
  @observable selQGroups = [];
  @observable isQModal = false;
  @observable questionGroupList = {};

  constructor(props) {
    super(props);

    this.updateQuestionGroup = this.updateQuestionGroup.bind(this);
  }


  @action
  getInit = async (isForce) => {

    if(isForce) {
      this.subCateogry = [];
      this.seFormTemp = {};
      this.selQGroups = [];
      this.isQModal = false;
      this.questionGroupList = {};
    }

    let questionList = await this.props.getQuestionList();
    this.questionGroupList = await this.props.getQuestionGroupList();
    this.questionList = questionList.docs;
  };

  componentWillMount(prevProps, prevState){
    this.seFormTemp = this.props.formTempList[this.props.selectedFormTepm];
    this.selQGroups = this.seFormTemp.questionGroups;
    this.getInit();
  }

  componentWillReceiveProps(nextProps){

    if(nextProps.selectedFormTepm !== this.props.selectedFormTepm ) {
      if(nextProps.selectedFormTepm && nextProps.selectedFormTepm != '') {
        this.seFormTemp = this.props.formTempList[nextProps.selectedFormTepm];
        this.selQGroups = this.seFormTemp.questionGroups;
      }
      else {
        this.getInit(true)
      }

    }

  }

  componentDidUpdate(prevProps, prevState){

    // this.selQGroups = this.props.formTempList[this.props.selectedFormTepm].questionGroups
    // console.log(this.selQGroups);
  }



  // onChangeMC = function(event) {
  //   this.subCateogry = this.props.category.depth_2[event.target.value];
  // };
  //
  // onChangeSC = function(event) {
  //   console.log(this.props.formTempList[event.target.value])
  // };

  onClickQItem = function(event, row, groupIndex, questionIndex) {

  };


  onClickQuestionGen = function(event) {
    this.isQModal = true;
  };

  toggle() {
    this.isQModal = !this.isQModal;
  }

  updateFormTemp(selQGroups) {

    let updateQGroups = _.pluck(selQGroups, '_id');
    let updateTmpl = {
      id : this.seFormTemp._id ? this.seFormTemp._id : this.seFormTemp.id,
      questionGroups : updateQGroups
    };
    this.props.updateFormTemp(updateTmpl)

  }

  updateQuestionGroup(selQGroups) {

    let updateQuestions = _.pluck(selQGroups.questions, '_id');

    let updateQGroup = {
      id : selQGroups._id ? selQGroups._id : selQGroups.id,
      questions : updateQuestions
    };

    this.props.updateQuestionGroup(updateQGroup)

  }




  render() {

    // QGroup
    const moveQGroup = (dragIndex, hoverIndex) => {


    };

    const dropQGroup = (item, hoverIndex) => {
      const dragQGroup = this.selQGroups[item.index];
      this.selQGroups.splice(item.index, 1);
      this.selQGroups.splice(hoverIndex, 0, dragQGroup);
      this.updateFormTemp(this.selQGroups);
    };

    const removeQGroupInTmpl = (gIndex) => {
      this.selQGroups.splice(gIndex, 1);
      this.updateFormTemp(this.selQGroups);
    };


    // Question
    const moveQuestion = (dragGroupIndex, dragIndex, hoverGroupIndex, hoverIndex) => {

      // const dragStep = this.selQGroups[dragGroupIndex].steps[dragIndex];
      //
      // this.selQGroups[dragGroupIndex].steps.splice(dragIndex, 1);
      // this.selQGroups[hoverGroupIndex].steps.splice(hoverIndex, 0, dragStep);
      // this.selQGroups.this.selQGroups[groupIndex]
      //
      // setCards(
      //     update(cards, {
      //       $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
      //     }),
      //   )
      // }
    };

    const dropAddQuestion = (item, hoverGroupIndex, hoverIndex) => {

      if(hoverIndex === 999) {
        this.selQGroups[hoverGroupIndex].questions.push(item.question);
      }
      else {
        this.selQGroups[hoverGroupIndex].questions.splice(hoverIndex, 0, item.question);
      }
      this.updateQuestionGroup(this.selQGroups[hoverGroupIndex]);
    };

    const dropQuestion = (item, hoverGindex, hoverIndex) => {
      const dragQuestion = this.selQGroups[item.gindex].questions[item.index];
      this.selQGroups[item.gindex].questions.splice(item.index, 1);
      this.selQGroups[hoverGindex].questions.splice(hoverIndex, 0, dragQuestion);
      this.updateQuestionGroup(this.selQGroups[hoverGindex]);
    };

    const removeQuestionInGroup = (groupIndex, questionIndex) => {
      this.selQGroups[groupIndex].questions.splice(questionIndex, 1);
      this.updateQuestionGroup(this.selQGroups[groupIndex]);
    };


    const placeholderQuestion = {
      title: "질문이 없습니다.",
      question: "질문리스트에서 드래그하여 추가하세요.",
      type: "text"
    };

    const addQuestionGroup = (data) => {
      let _this = this;
      if(!data.questions) {
        data.questions = [];
      }
      if(!data._id) {
        this.props.updateQuestionGroup(data, function(res) {
          _this.seFormTemp.questionGroups.push(res.data);
          let selectedFTObj = {
            id : _this.seFormTemp._id,
            questionGroups : _.pluck(_this.seFormTemp.questionGroups, '_id')
          };

          _this.props.updateFormTemp(selectedFTObj, function(res) {
            console.log(res)
          });
        });
      }
      else {
        _this.seFormTemp.questionGroups.push(data);
        let selectedFTObj = {
          id : _this.seFormTemp._id,
          questionGroups : _.pluck(_this.seFormTemp.questionGroups, '_id')
        };

        this.props.updateFormTemp(selectedFTObj, function(res) {
          console.log(res)
        });

      }
    };



    return (
      <Fragment>
        <Row className="justify-content-md-center">
          <Col lg="6" className="mb-0">
            <p>제목 : {this.seFormTemp.title}</p>
            {/*<InputGroup className="mb-3">*/}
            {/*<InputGroupAddon type="prepend">*/}
            {/*<InputGroupText>메인 카테고리</InputGroupText>*/}
            {/*</InputGroupAddon>*/}
            {/*<FormSelect value={this.seFormTemp.category.main.key} onChange={event => this.onChangeMC(event)}>*/}
            {/*<option value=''>선택</option>*/}
            {/*{this.props.category[1].map((row, i) => (*/}
            {/*<option key={i} value={row.key}>{row.ko}</option>*/}
            {/*))}*/}
            {/*</FormSelect>*/}
            {/*</InputGroup>*/}
          </Col>
          <Col lg="6" className="mb-0">
            {/*<p>종류 : {this.seFormTemp.categoryLable}</p>*/}
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg="12" className="mb-0">
            <ul className="questionGroups">
              {this.selQGroups.map((row, g) => (
                <QGroupSortInTmpl key={g} index={g} group={row} moveQGroup={moveQGroup} dropQGroup={dropQGroup}>
                  <p><b>그룹 : </b>{row.title} <i className="material-icons" onClick={event => removeQGroupInTmpl(g)}>delete</i></p>
                  <Row className="justify-content-md-end mb-3">
                    <Col lg="12" className="mb-0">
                      <ListGroup className="mb-2">
                        {row.questions && row.questions.length > 0 ?
                          row.questions.map((question, s) => (
                            <QuestionSortInGroup gindex={g} key={s} index={s} question={question} onClickCB={this.onClickQItem} moveQuestion={moveQuestion} dropQuestion={dropQuestion} dropAddQuestion={dropAddQuestion} cbRemoveQuestionInGroup={removeQuestionInGroup}/>
                          ))
                          :
                          null
                        }
                        <QuestionSortInGroup gindex={g} key={999} index={999} question={placeholderQuestion} moveQuestion={moveQuestion} dropQuestion={dropQuestion} dropAddQuestion={dropAddQuestion}/>
                      </ListGroup>
                    </Col>
                  </Row>

                </QGroupSortInTmpl>
              ))}
            </ul>

          </Col>
          <Col lg="12" className="mb-0">
            <NewGroupForm questionGroupList={this.questionGroupList} isNewQuestionGroup={true} cd={addQuestionGroup}/>
          </Col>
        </Row>


        {/*<QuestionDragItem name='질문지' className="px-3"/>*/}

        {/*<DropBox></DropBox>*/}

      </Fragment>
    )
  }
}

editFormTemp.propTypes = {
  // category: PropTypes.object.isRequired,
  formTempList: PropTypes.array.isRequired,
  selectedFormTepm: PropTypes.string.isRequired
};

export default editFormTemp



