import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import gql from "../utils/gql";
import _ from "underscore";
import {reactLocalStorage as Storage} from "reactjs-localstorage";

export class Common {

  @observable category = [];
  @observable orgCategory = [];


  @action getConBoard = ( boardkey ) => {

    let result = Storage.getObject('board_' + boardkey);
    result.boardKeys = Storage.getObject('boardKeys');
    if(result) {
      return result;
    }
    else {
      console.log(result)
    }

  };



  @action getSetting = ( cb ) => {

    let v = Storage.get('v');

    //test
    v = '0.0.0'
    let params = {
      type: 'query',
      operation: 'getSetting',
      variables: { v  : v ? v : '0.0.0' },
      getData: 'data'
    };

    const gpqParams = gql(params);
    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];
        if(result) {
          if(result.data.v) {
            Storage.set('v', result.data.v);
          }
          if(result.data.ConBoard) {
            let boardKeys = [];
            result = _.indexBy(result.data.ConBoard, 'key')
            for(let key in result) {
              Storage.setObject('board_' + key , result[key]);
              boardKeys.push(key)
            }
            Storage.setObject('boardKeys' , boardKeys);
          }
        }
        else {
          throw res.data.errors;
        }

      })
      .catch(function (error) {
        return error;
      });
  };


  @action delDoc = (operation, param, cb ) => {

    let params = {
      type: 'mutation',
      operation: operation,
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action getCategory = (param, cb) => {

    let category = this.category;
    let orgCategory = this.orgCategory;
    if(category.length > 0) {
      return category;
    }
    else {
      let params = {
        type: 'query',
        operation: 'getCategory',
        variables: param ? param : {},
        getData: 'id cls depth label order'
      };

      const gpqParams = gql(params);

      return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            orgCategory = res.data.data[params.operation];

            let row = {};
            let proc_1 = _.groupBy(res.data.data[params.operation], function(item){ return item.depth; });

            _.each(proc_1, function(data, index) {

              let proc_2;

              if(index === "1") {
                proc_2 = data;
              }
              else {
                proc_2 = _.groupBy(data, function(item){ return item.key });
              }
              row['depth_' + index] = proc_2;
              category = row;
            });
            if(typeof cb === 'function') {
              cb(category);
            }
            else {

              return proc_1;
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    }

  };



  @action updateCategory = (param, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateCategory',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action updateCategoryOrder = (param, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateCategoryOrder',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


}
