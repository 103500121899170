import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import {
  Row,
  Col,
  ListGroup
} from "shards-react";

import './style.scss';

const QGroupSortInTmpl = ({children, group, index, moveQGroup, dropQGroup}) => {

  const ref = useRef(null);
  const [{ isOver, canDrop }, drop, preview] = useDrop({
    accept: 'QGroup',
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    hover(item, monitor) {

      if(!group._id) {
        return
      }

      if (!ref.current) {
        return
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveQGroup(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      //item.index = hoverIndex
    },
    drop(item, monitor) {
      const hoverIndex = index;
      if(item.isAdd) {
        //todo
        //dropAddStep(item, gindex, hoverIndex);
      }
      else {
        if(!group._id) {
          return
        }
        //todo
        dropQGroup(item, hoverIndex);
      }

    }
  });
  const isActive = isOver && canDrop;
  let backgroundColor = '#fff';
  if (isActive) {
    backgroundColor = 'darkgreen';
  } else if (canDrop) {
    backgroundColor = '#ffffff';
  }


  const [{ isDragging }, drag] = useDrag({
    item: { type: 'QGroup', group, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },

  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));


  return (
    <li className='QGroup' ref={ref}>{children}</li>
  )
};
export default QGroupSortInTmpl
