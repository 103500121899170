import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";


class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: [
        // {
        //   title: "통계 및 분석",
        //   to: "/blog-overview",
        //   htmlBefore: '<i class="material-icons">insert_chart</i>',
        //   htmlAfter: ""
        // },
        {
          title: "웹투앱",
          htmlBefore: '<i class="material-icons">assignment_ind</i>',
          to: "/web2app",
        },
        {
          title: "고도스킨",
          htmlBefore: '<i class="material-icons">verified_user</i>',
          to: "/godoskin",
        },
        {
          title: "카페24스킨",
          htmlBefore: '<i class="material-icons">verified_user</i>',
          to: "/cafeskin",
        },
        // {
        //   title: "질문지 생성",
        //   htmlBefore: '<i class="material-icons">assignment</i>',
        //   to: "/question-form",
        // },
        // {
        //   title: "상담",
        //   htmlBefore: '<i class="material-icons">assignment_ind</i>',
        //   to: "/cousel",
        // },
        // {
        //   title: "회원",
        //   htmlBefore: '<i class="material-icons">supervisor_account</i>',
        //   to: "/user",
        // },
        // {
        //   title: "공지사항",
        //   htmlBefore: '<i class="material-icons">notification_important</i>',
        //   to: "/board/notice",
        // },
        // {
        //   title: "이용후기",
        //   htmlBefore: '<i class="material-icons">hearing</i>',
        //   to: "/board/review",
        // },
        // {
        //   title: "문의게시판",
        //   htmlBefore: '<i class="material-icons">question_answer</i>',
        //   to: "/board/qna",
        // },
        // {
        //   title: "카테고리 관리",
        //   htmlBefore: '<i class="material-icons">layers</i>',
        //   to: "/categoryMng",
        // },

        // {
        //   title: "Blog Posts",
        //   htmlBefore: '<i class="material-icons">vertical_split</i>',
        //   to: "/blog-posts",
        // },
        // {
        //   title: "Add New Post",
        //   htmlBefore: '<i class="material-icons">note_add</i>',
        //   to: "/add-new-post",
        // },
        // {
        //   title: "Forms & Components",
        //   htmlBefore: '<i class="material-icons">view_module</i>',
        //   to: "/components-overview",
        // },
        // {
        //   title: "Tables",
        //   htmlBefore: '<i class="material-icons">table_chart</i>',
        //   to: "/tables",
        // },
        // {
        //   title: "User Profile",
        //   htmlBefore: '<i class="material-icons">person</i>',
        //   to: "/user-profile-lite",
        // },
        // {
        //   title: "Errors",
        //   htmlBefore: '<i class="material-icons">error</i>',
        //   to: "/errors",
        // }
      ]
    };

    this.onChange = this.onChange.bind(this);
  }

  // componentWillMount() {
  //   Store.addChangeListener(this.onChange);
  // }
  //
  // componentWillUnmount() {
  //   Store.removeChangeListener(this.onChange);
  // }

  onChange() {
    this.setState({
      ...this.state,
      navItems: this.state.navItems
    });
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
