import React, {Component, Fragment, useState}  from 'react';

import {
  Container,
  Row,
  Col,

  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormSelect,
  FormCheckbox,
  FormInput,


} from "shards-react";


import './style.scss';




const NewGroupForm = ({questionGroupList, cd}) => {

  // let addQuestionGroup = { };
  const [addQuestionGroup, setAddQuestionGroup] = useState(
    {}
  );
  const [isNewQuestionGroup, setIsNewQuestionGroup] = useState(
    true
  );

  const [newQuoupTitle, setNewQuoupTitle] = useState(
    ''
  );

  const onChange = function(event) {
    if(event.target.localName === 'select') {
      if(event.target.value === 'new' ) {
        setIsNewQuestionGroup(true);
      }
      else {
        setIsNewQuestionGroup(false);
        let selectedQGObj = questionGroupList.docs[event.target.selectedIndex-1];
        setAddQuestionGroup({_id:selectedQGObj._id, title:selectedQGObj.title, questions:selectedQGObj.questions})
      }
    }
    else {
      eval(event.target.name)(event.target.value);
      setAddQuestionGroup({title:event.target.value})
    }
  };

  const doAddQuestionGroup = function(event) {
    if(addQuestionGroup._id || addQuestionGroup.title) {
      cd(addQuestionGroup);
    }

  };

  return (
    <Fragment>
      <Row form>
        <Col md={isNewQuestionGroup ? '6': '10'}>
          <FormSelect onChange={onChange.bind(this)}>
            <option value="new">새로만들기</option>
            {questionGroupList.totalDocs > 0 ?
              questionGroupList.docs.map((row, i) => (
                <option key={i} value={row._id}>{row.title + ' (' + row.questions.length +')'}
                  : {
                    row.questions.length > 0 ?
                      row.questions.map((row, i) => (
                        i === 0 ? row.title: ' / ' + row.title
                      ))
                      : null
                  }
                </option>
              )) :
              null
            }
          </FormSelect>
        </Col>
        {isNewQuestionGroup ?
          <Col md="4" className="form-group">
            <FormInput placeholder="추가할 그룹명 입력" name="setNewQuoupTitle" value={newQuoupTitle} onChange={onChange.bind(this)} />
          </Col> :
          null
        }
        <Col md="2" className="form-group">
          <Button block onClick={doAddQuestionGroup.bind(this)}>그룹 추가</Button>
        </Col>
      </Row>
    </Fragment>
  )
};
export default NewGroupForm
