import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import gql from "../utils/gql";

export class Upload {

    constructor(props) {

        this.uAxios = axios.create({
            baseURL: '/'
        });
    }


    @action uploads = (files, params, cb) => {

        let formData = new FormData();


        // 주의!!! file 보다 먼저 추가해야된다.
        for(let key in params) {
            formData.append('target', 'board')
        }

        // 나중에 추가
        for(let key in files) {
            formData.append('file', files[key])
        }


        return this.uAxios.post('/upload', formData)
            .then(function (res) {
                if(res.status === 200) {
                    if(typeof cb === 'function') {
                        cb(res.data);
                    }
                    else {
                        return res.data;
                    }
                }

            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };


}
